<template>
  <div>
    <!--供应单位名称-->
    <div style="display: flex">
      <el-input size="small" style="width: 15%;margin-right: 5%" v-model="search_source_name" placeholder="请输入单位名称" @input="get_all_source"/>
      <el-select v-model="source_select" class="m-2" placeholder="请选择供应单位\生产厂家" size="small" style="width: 15%" @change="get_all_source">
        <el-option
            v-for="item in source_selects"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
      <el-button style="margin-left: 5%" type="primary" size="small"  @click="diaSaveEquimentSource = true">新增单位</el-button>
    </div>
  <!--  单位列表-->
    <div>
      <el-table :data="equiment_sources" style="width: 100%">
        <el-table-column prop="sourceName" label="单位名称" width="180"/>
        <el-table-column prop="a" label="类别" width="280">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.flag === '0'">供应单位</el-tag>
            <el-tag v-if="scope.row.flag === '1'">生产厂家</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="danger" @click="remove_equiment_source(scope.row)"> 删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  分页-->
    <div v-if="first" style="float: right;margin-top: 20px;">
      <el-pagination v-model:currentPage="pagenum"
                     :small="true"
                     background layout="prev, pager, next" :total="totals">
      </el-pagination>
    </div>
  <!--  新增单位-->
    <div>
      <el-dialog
          v-model="diaSaveEquimentSource"
          title="新增单位"
          width="30%"
          :before-close="handleCloseEquiment">
        <div>
          <div style="margin-left: 30%;margin-top: 5%">

            <div style="display: flex">
              <el-tag>单位类别</el-tag>
              <el-select v-model="source_select_new" class="m-2" placeholder="请选择供应单位\生产厂家" size="small" style="width: 60%"
                         >
                <el-option
                    v-for="item in source_selects"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>单位名称</el-tag>
              <el-input v-model="equiment_material_source" size="small" style="width: 60%;" placeholder="请输入材料名称" @keyup.enter.native="update_equiment_source"></el-input>
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary " @click="update_equiment_source">提交</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name: "equiment_source",
  inject: ['reload'],
  data(){
    return{
      search_source_name: '',
      source_select: '',
      source_selects: [
        {
          value: '0',
          label: '供应单位'
        },
        {
          value: '1',
          label: '生产厂家'
        }
      ],
      equiment_sources: [],
      first: true,
      pagenum: 1,
      totals: 0,
      pagenum2: 1,
      totals2: 0,
      diaSaveEquimentSource: false,
      source_select_new: '',
      equiment_material_source: ''
    }
  },
  created() {
    this.get_all_source()
  },
  methods:{
    get_all_source(){
      this.axios.post('/equimentSource/getAllSource', (response) => {
        this.equiment_sources = response.obj.records
        this.totals = response.obj.pages * 10
      },{
        flag: this.source_select,
        source_name: this.search_source_name,
        pageCount: 1
      })
    },
    remove_equiment_source(item){
      this.axios.post('/equimentSource/removeById', (response) => {
        if (response.obj){
          ElMessage({
            message: '删除成功',
            type: 'success'
          })
          this.reload()
        }else {
          ElMessage.error('删除失败，请联系科信部!')
        }
      },{
        uuid: item.id
      })
    },
    handleCloseEquiment(){
      this.equiment_material_source = ''
      this.source_select_new = ''
      this.diaSaveEquimentSource = false
      this.reload()
    },
    update_equiment_source(){
      this.axios.post('/equimentSource/saveEquimentSource', (response) => {
        if (response.obj){
          ElMessage({
            message: '新增成功',
            type: 'success'
          })
          this.source_select_new = ''
          this.equiment_material_source = ''
        }else {
          ElMessage.error('新增出错，请联系科信部!')
        }
      },{
        flag: this.source_select_new,
        source_name: this.equiment_material_source
      })
    }
  },
  watch: {
    pagenum(){
      this.axios.post('/equimentSource/getAllSource', (response) => {
        this.equiment_sources = response.obj.records
        this.totals = response.obj.pages * 10
      },{
        flag: this.source_select,
        source_name: this.search_source_name,
        pageCount: this.pagenum
      })
    }
  }
}
</script>

<style scoped>

</style>
