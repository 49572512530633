<template>
  <div>
  <!--  材料名称-->
    <div style="display: flex">
      <el-input size="small" style="width: 20%;margin-right: 5%" v-model="search_equiment_material_name" placeholder="请输入材料名称" @input="get_equiment_material_name"/>
      <el-button type="primary" size="small"  @click="diaSaveEquimentMaterial = true">新增材料</el-button>
    </div>
  <!--  材料列表-->
    <div>
      <el-table :data="equiment_materials" style="width: 100%">
        <el-table-column prop="materialName" label="材料名称" width="180"/>
        <el-table-column prop="materialModel" label="规格型号" width="280"/>
        <el-table-column prop="materialUnit" label="计量单位" width="280"/>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="danger" @click="remove_equiment_material(scope.row)"> 删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  分页-->
    <div v-if="first" style="float: right;margin-top: 20px;">
      <el-pagination v-model:currentPage="pagenum"
                     :small="true"
                     background layout="prev, pager, next" :total="totals">
      </el-pagination>
    </div>
    <div v-if="!first" style="float: right;margin-top: 20px;">
      <el-pagination v-model:currentPage="pagenum2"
                     :small="true"
                     background layout="prev, pager, next" :total="totals2">
      </el-pagination>
    </div>
    <!--  新增设备-->
    <div>
      <el-dialog
          v-model="diaSaveEquimentMaterial"
          title="新增材料"
          width="30%"
          :before-close="handleCloseEquiment">
        <div>
          <div style="margin-left: 30%;margin-top: 5%">
            <div style="display: flex">
              <el-tag>设备名称</el-tag>
              <el-input v-model="equiment_material_name" size="small" style="width: 60%;" placeholder="请输入材料名称"></el-input>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>规格型号</el-tag>
              <el-input v-model="equiment_material_model" size="small" style="width: 60%;" placeholder="请输入材料规格型号"></el-input>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>计量单位</el-tag>
              <el-input v-model="equiment_material_unit" size="small" style="width: 60%;" placeholder="请输入材料计量单位" @keyup.enter.native="update_equiment_material"></el-input>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>税率</el-tag>
              <el-input v-model="equiment_material_cess" size="small" style="width: 60%;" placeholder="请输入材料计量单位" @keyup.enter.native="update_equiment_material"></el-input>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>含税单价</el-tag>
              <el-input v-model="equiment_material_money" size="small" style="width: 60%;" placeholder="请输入材料计量单位" @keyup.enter.native="update_equiment_material"></el-input>
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary " @click="update_equiment_material">提交</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name: "equiment_material",
  inject: ['reload'],
  data(){
    return{
      search_equiment_material_name: '',
      equiment_materials: [],
      diaSaveEquimentMaterial: false,
      first: true,
      totals2: 0,
      pagenum2: 1,
      totals: 0,
      pagenum: 1,
      equiment_material_unit: '',
      equiment_material_model: '',
      equiment_material_name: '',
      equiment_material_money: '',
      equiment_material_cess: ''
    }
  },
  created() {
    this.get_all_material()
  },
  methods:{
    get_all_material(){
      this.axios.post('/equimentMaterial/getAllEquimentMaterial', (response) => {
        this.equiment_materials = response.obj.records
        this.totals = response.obj.pages * 10
      },{
        pageCount: 1
      })
    },
    remove_equiment_material(item){
      this.axios.post('/equimentMaterial/removeById', (response) => {
        if (response.obj){
          ElMessage({
            message: '删除成功',
            type: 'success'
          })
          this.reload()
        }else {
          ElMessage.error('删除失败，请联系科信部!')
        }
      },{
        uuid: item.id
      })
    },
    get_equiment_material_name(){
      this.first = this.search_equiment_material_name === '';
      this.axios.post('/equimentMaterial/getAllEquimentMaterialByName', (response) => {
        this.equiment_materials = response.obj.records
        this.totals2 = response.obj.pages * 10
      },{
        name: this.search_equiment_material_name,
        pageCount: 1
      })
    },
    update_equiment_material(){
      this.axios.post('/equimentMaterial/saveEquimentMaterial', (response) => {
        if (response.obj){
          ElMessage({
            message: '新增成功',
            type: 'success'
          })
          this.equiment_material_name = ''
          this.equiment_material_model = ''
          this.equiment_material_unit = ''
          this.equiment_material_cess = ''
          this.equiment_material_money = ''
        }else {
          ElMessage.error('新增出错，请联系科信部!')
        }
      },{
        material_name: this.equiment_material_name,
        material_model: this.equiment_material_model,
        unit: this.equiment_material_unit,
        material_money: this.equiment_material_money,
        material_cess: this.equiment_material_cess
      })
    },
    handleCloseEquiment(){
      this.diaSaveEquimentMaterial = false
      this.equiment_material_name = ''
      this.equiment_material_model = ''
      this.equiment_material_unit = ''
      this.equiment_material_cess = ''
      this.equiment_material_money = ''
      this.reload()
    }
  },
  watch: {
    pagenum(){
      this.axios.post('/equimentMaterial/getAllEquimentMaterial', (response) => {
        this.equiment_materials = response.obj.records
        this.totals = response.obj.pages * 10
      },{
        pageCount: this.pagenum
      })
    },
    pagenum2(){
      this.axios.post('/equimentMaterial/getAllEquimentMaterialByName', (response) => {
        this.equiment_materials = response.obj.records
        this.totals2 = response.obj.pages * 10
      },{
        name: this.search_equiment_material_name,
        pageCount: this.pagenum2
      })
    }
  }
}
</script>

<style scoped>

</style>
