<template>
  <div>
    <!--设备名称-->
    <div style="display: flex">
      <el-input size="small" style="width: 20%;margin-right: 5%" v-model="search_equiment_name" placeholder="请输入设备名称" @input="get_equiment_name"/>
      <el-button type="primary" size="small"  @click="diaSaveEquiment = true">新增设备</el-button>
    </div>
    <!--设备列表-->
    <div>
      <el-table :data="equiments" style="width: 100%">
        <el-table-column prop="equipmentName" label="设备名称" width="180"/>
        <el-table-column prop="equipmentModel" label="规格型号" width="280"/>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="primary" @click="update_equiment(scope.row)"> 修改</el-button>
            <el-button type="danger" @click="remove_equiment(scope.row)"> 删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  <!--  新增设备-->
    <div>
      <el-dialog
          v-model="diaSaveEquiment"
          title="新增设备"
          width="30%"
          :before-close="handleCloseEquiment">
        <div>
          <div style="margin-left: 30%;margin-top: 5%">
            <div style="display: flex">
              <el-tag>设备名称</el-tag>
              <el-input v-model="equiment_name" size="small" style="width: 60%;" placeholder="请输入设备名称"></el-input>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>规格型号</el-tag>
              <el-input v-model="equiment_model" size="small" style="width: 60%;" placeholder="请输入设备规格型号" @keyup.enter.native="save_equiment_basis"></el-input>
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary " @click="save_equiment_basis">提交</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--  修改设备-->
    <div>
      <el-dialog
          v-model="diaUpdateEquiment"
          title="修改设备"
          width="30%"
          :before-close="handleCloseEquiment">
        <div>
          <div style="margin-left: 30%;margin-top: 5%">
            <div style="display: flex">
              <el-tag>设备名称</el-tag>
              <el-input v-model="equiment_name" size="small" style="width: 60%;" placeholder="请输入设备名称"></el-input>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>规格型号</el-tag>
              <el-input v-model="equiment_model" size="small" style="width: 60%;" placeholder="请输入设备规格型号" @keyup.enter.native="update_equiment_basis"></el-input>
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary " @click="update_equiment_basis">提交</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  <!--  分页-->
    <div v-if="first" style="float: right;margin-top: 20px;">
      <el-pagination v-model:currentPage="pagenum"
                     :small="true"
                     background layout="prev, pager, next" :total="totals">
      </el-pagination>
    </div>
    <div v-if="!first" style="float: right;margin-top: 20px;">
      <el-pagination v-model:currentPage="pagenum2"
                     :small="true"
                     background layout="prev, pager, next" :total="totals2">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name: "equiment_basis",
  inject: ['reload'],
  data(){
    return{
      equiment_name: '',
      equiment_model: '',
      diaSaveEquiment: false,
      search_equiment_name: '',
      equiments: [],
      diaUpdateEquiment: false,
      uuid: '',
      first: true,
      pagenum2: 1,
      totals2: 0,
      pagenum: 1,
      totals: 0
    }
  },
  created() {
    this.get_all_equiment()
  },
  methods:{
    get_all_equiment(){
      this.axios.get('/equimentBasis/getAllEquiment', (response) => {
        this.equiments = response.obj.records
        this.totals = response.obj.pages * 10
      },{
        pageCount: 1
      })
    },
    save_equiment_basis(){
      this.axios.post('/equimentBasis/saveEquimentBasis', (response) => {
        if (response.obj){
          ElMessage({
            message: '新增成功',
            type: 'success'
          })
          this.equiment_name = ''
          this.equiment_model = ''
        }else {
          ElMessage.error('新增出错，请联系科信部!')
        }
      },{
        equiment_name: this.equiment_name,
        equiment_model: this.equiment_model
      })
    },
    handleCloseEquiment(){
      this.equiment_name = ''
      this.equiment_model = ''
      this.diaSaveEquiment = false
      this.reload()
    },
    update_equiment(item){
      this.diaUpdateEquiment = true
      this.equiment_name = item.equipmentName
      this.equiment_model = item.equipmentModel
      this.uuid = item.id
    },
    update_equiment_basis(){
      this.axios.post('/equimentBasis/updateEquiment', (response) => {
        if (response.obj){
          ElMessage({
            message: '修改成功',
            type: 'success'
          })
          this.equiment_name = ''
          this.equiment_model = ''
          this.uuid = ''
          this.diaUpdateEquiment = false
          this.reload()
        }else {
          ElMessage.error('修改失败，请联系科信部!')
        }
      },{
        uuid: this.uuid,
        equiment_name: this.equiment_name,
        equiment_model: this.equiment_model
      })
    },
    remove_equiment(item){
      this.axios.post('/equimentBasis/removeEquiment', (response) => {
        if (response.obj){
          ElMessage({
            message: '删除成功',
            type: 'success'
          })
          this.reload()
        }else {
          ElMessage.error('删除失败，请联系科信部!')
        }
      },{
        uuid: item.id
      })
    },
    get_equiment_name(){
      this.first = this.search_equiment_name === '';
      this.axios.post('/equimentBasis/getEquimentByName', (response) => {
        this.equiments = response.obj.records;
      },{
        name: this.search_equiment_name,
        pageCount: 1
      })
    }
  },
  watch: {
    pagenum(){
      this.axios.get('/equimentBasis/getAllEquiment', (response) => {
        this.equiments = response.obj.records
        this.totals = response.obj.pages * 10
      },{
        pageCount: this.pagenum
      })
    },
    pagenum2(){
      this.axios.post('/equimentBasis/getEquimentByName', (response) => {
        this.equiments = response.obj.records
        this.totals2 = response.obj.pages * 10
      },{
        name: this.search_equiment_name,
        pageCount: this.pagenum2
      })
    }
  }
}
</script>

<style scoped>

</style>
