<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }" @click="reall">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">系统管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!--  基础信息管理-->
    <div id="forms_div2">
      <el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
        <el-tab-pane label="设备信息" name="a"><Equiment_basis></Equiment_basis></el-tab-pane>
        <el-tab-pane label="材料信息" name="b"><Equiment_material></Equiment_material></el-tab-pane>
        <el-tab-pane label="供应单位" name="c"><Equiment_source></Equiment_source></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import Equiment_basis from "@/views/equiment/equiment_manage/equiment_basis";
import Equiment_material from "@/views/equiment/equiment_manage/equiment_material";
import Equiment_source from "@/views/equiment/equiment_manage/equiment_source";

export default {
  name: "equiment_manage",
  components: {Header, Equiment_basis, Equiment_material, Equiment_source},
  data(){
    return{
      activeName: 'a'
    }
  },
  created() {
    if (sessionStorage.getItem('equiment_manage_names')){
      this.activeName = sessionStorage.getItem('equiment_manage_names');
    }
  },
  methods:{
    handleClick(tab, event){
      // 点击tab后触发事件，修改显示页面，将状态保存在sessionStorage里面
      sessionStorage.setItem('equiment_manage_names', tab.props.name)
    },
    reall(){
      sessionStorage.removeItem('equiment_manage_names');
    },
  }
}
</script>

<style scoped>
#admindiv1 {
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}
#forms_div2{
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 500px;
}
</style>
